import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReceiptThermal from "./Receipt/ReceiptThermal";

const NotaPage = () => {
  const { orderId } = useParams();
  const [transactionData, setTransactionData] = useState(null);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get(
          `https://api.ajegjogja.com/api/public/order/${orderId}`
        );
        setTransactionData(response.data);
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };

    fetchTransactionData();
  }, [orderId]);

  if (!transactionData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ReceiptThermal transactionData={transactionData} userName={userName} />
    </div>
  );
};

export default NotaPage;
