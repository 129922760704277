// path: src/features/Sales/Receipt/ReceiptThermal.js
import React from "react";
import { Box, Typography, Divider, Grid } from "@mui/material";
import { ArrowBack, Instagram, Language, Print } from "@mui/icons-material";
import theme from "../theme";

const ReceiptThermal = ({ transactionData, userName }) => {
  const formatRupiah = (value) => {
    if (!value) return "Rp 0";
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const maskPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const length = phoneNumber.length;
    if (length <= 4) return "XXXX";
    return phoneNumber.slice(0, -4) + "XXXX";
  };

  const calculateSubtotal = () => {
    const itemsSubtotal = transactionData.order_items.reduce(
      (total, item) => total + item.subtotal,
      0
    );
    return itemsSubtotal;
  };

  const calculateRemainingBalance = () => {
    const total = transactionData.total_harga;
    const paid = transactionData.payment.jumlah_pembayaran;
    return total - paid;
  };

  if (!transactionData) {
    return <Typography>No transaction data available</Typography>;
  }

  const renderCustomizations = (item) => {
    const { formData, warna, finishing } = item;

    if (formData && formData.length > 0) {
      return (
        <Box>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mt: 1, fontWeight: 600 }}
          >
            Custom: {formatRupiah(transactionData.additionalCost)}
          </Typography>
          <Grid container>
            {formData.map((option, index) => (
              <React.Fragment key={index}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    {option.jenis}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    : {option.nilai}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    textAlign={"right"}
                  >
                    {formatRupiah(option.harga)}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={4}>
              {warna && (
                <Typography variant="body2" color="textSecondary">
                  Warna
                </Typography>
              )}
              {finishing && (
                <Typography variant="body2" color="textSecondary">
                  Finishing
                </Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              {warna && (
                <Typography variant="body2" color="textSecondary">
                  : {warna}
                </Typography>
              )}
              {finishing && (
                <Typography variant="body2" color="textSecondary">
                  : {finishing}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      );
    } else {
      return (
        <Grid container>
          <Grid item xs={4}>
            {warna && (
              <Typography variant="body2" color="textSecondary">
                Warna
              </Typography>
            )}
            {finishing && (
              <Typography variant="body2" color="textSecondary">
                Finishing
              </Typography>
            )}
          </Grid>

          <Grid item xs={6}>
            {warna && (
              <Typography variant="body2" color="textSecondary">
                : {warna}
              </Typography>
            )}
            {finishing && (
              <Typography variant="body2" color="textSecondary">
                : {finishing}
              </Typography>
            )}
          </Grid>
        </Grid>
      );
    }
  };

  const renderSpecifications = (item) => {
    return (
      <Grid container spacing={1}>
        <Grid item container spacing={1} xs={12}>
          <Grid item xs={4}>
            {(item.panjang || item.lebar || item.tinggi) && (
              <Typography variant="body2">Dimensi</Typography>
            )}
            {item.kain && <Typography variant="body2">Kain</Typography>}
            {item.kaki && <Typography variant="body2">Kaki</Typography>}
            {item.dudukan && <Typography variant="body2">Dudukan</Typography>}
            {item.bantal_peluk !== null && item.bantal_peluk !== undefined && (
              <Typography variant="body2">B Peluk</Typography>
            )}
            {item.bantal_sandaran !== null &&
              item.bantal_sandaran !== undefined && (
                <Typography variant="body2">B Sandaran</Typography>
              )}
            {item.kantong_remot !== null &&
              item.kantong_remot !== undefined && (
                <Typography variant="body2">K Remot</Typography>
              )}
            {item.puff !== null && item.puff !== undefined && (
              <Typography variant="body2">Puff</Typography>
            )}
          </Grid>
          <Grid item xs={8}>
            {(item.panjang || item.lebar || item.tinggi) && (
              <Typography variant="body2">
                : {item.panjang || "-"} x {item.lebar || "-"} x{" "}
                {item.tinggi || "-"} cm
              </Typography>
            )}
            {item.kain && (
              <Typography variant="body2">: {item.kain}</Typography>
            )}
            {item.kaki && (
              <Typography variant="body2">: {item.kaki}</Typography>
            )}
            {item.dudukan && (
              <Typography variant="body2">: {item.dudukan}</Typography>
            )}
            {item.bantal_peluk !== null && item.bantal_peluk !== undefined && (
              <Typography variant="body2">: {item.bantal_peluk}</Typography>
            )}
            {item.bantal_sandaran !== null &&
              item.bantal_sandaran !== undefined && (
                <Typography variant="body2">
                  : {item.bantal_sandaran}
                </Typography>
              )}
            {item.kantong_remot !== null &&
              item.kantong_remot !== undefined && (
                <Typography variant="body2">: {item.kantong_remot}</Typography>
              )}
            {item.puff !== null && item.puff !== undefined && (
              <Typography variant="body2">
                : {item.puff ? "Ya" : "Tidak"}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={12}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      className="printable-content"
      sx={{
        backgroundColor: "white",
        padding: 2,
        boxShadow: theme.boxShadow.primary,
        width: "80mm",
        "@media print": {
          width: "100%",
          height: "auto",
          boxShadow: "none",
        },
      }}
    >
      <Grid container justifyItems={"start"}>
        <Grid item xs={3}>
          <img
            src="/assets/icons/logo-ajeg-hitam-aplikasi.webp"
            alt="Logo"
            style={{ width: "50px", height: "auto" }}
          />
        </Grid>
        <Grid item xs={8} textAlign={"left"}>
          <Typography variant="subtitle1">Ajeg Furniture</Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            Jl. Raya Jogja - Solo KM 10
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            Telp. 087739070444
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1 }} />

      <Typography variant="h6" sx={{ textAlign: "center", fontSize: 16 }}>
        Struk Pesanan
      </Typography>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            No. Pesanan
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Tanggal
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Sales
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">
            : {transactionData.orderNumber}
          </Typography>
          <Typography variant="body2">
            : {formatDate(transactionData.tanggal_transaksi)}
          </Typography>
          <Typography variant="body2">: {userName}</Typography>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />
      <Box>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Nama Pelanggan
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Nomor HP
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              : {transactionData.customer.nama}
            </Typography>
            <Typography variant="body2">
              : {maskPhoneNumber(transactionData.customer.nomorHp)}
            </Typography>
          </Grid>
        </Grid>
        {/* Tambahkan detail lain dari transactionData */}
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography variant="body1">Produk:</Typography>
        {transactionData.order_items.map((item, index) => (
          <Box key={index} sx={{ my: 1 }}>
            <Typography variant="body2" sx={{ fontWeight: "600" }}>
              {item.nama}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              textAlign={"right"}
            >
              {formatRupiah(item.harga_satuan)} x {item.jumlah}
            </Typography>
            {renderSpecifications(item)}
            {renderCustomizations(item)}
            <Grid container sx={{ borderTop: "1px solid", my: 1 }}>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Subtotal
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  textAlign={"right"}
                >
                  {formatRupiah(item.subtotal)}
                </Typography>
              </Grid>
            </Grid>
            {index < transactionData.order_items.length - 1 && (
              <Divider sx={{ my: 2 }} />
            )}
          </Box>
        ))}
      </Box>
      {/* Produk dan total harga rendering */}
      <Box sx={{ my: 2 }}>
        <Grid container>
          <Grid item xs={7}>
            <Typography variant="body2" color="textSecondary">
              Sub Total
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Ongkir
            </Typography>
            {transactionData.discountValue > 0 && (
              <Typography variant="body2" color="textSecondary">
                Diskon
              </Typography>
            )}
            <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
              Total
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Jml Pembayaran
            </Typography>
            {calculateRemainingBalance() !== 0 && (
              <Typography variant="body2" color="textSecondary">
                Sisa
              </Typography>
            )}
          </Grid>
          <Grid item xs={5} textAlign={"right"}>
            <Typography variant="body2" color="textSecondary">
              {formatRupiah(calculateSubtotal())}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {formatRupiah(transactionData.shippingCost)}
            </Typography>
            {transactionData.discountValue > 0 && (
              <Typography variant="body2" color="textSecondary">
                -{formatRupiah(transactionData.discountValue)}
              </Typography>
            )}
            <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
              {formatRupiah(transactionData.total_harga)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {formatRupiah(transactionData.payment.jumlah_pembayaran)}
            </Typography>
            {calculateRemainingBalance() !== 0 && (
              <Typography variant="body2" color="textSecondary">
                {formatRupiah(calculateRemainingBalance())}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ my: 1 }} />
        <Typography textAlign={"center"}>Terimakasih</Typography>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 1,
          }}
        >
          <Instagram sx={{ fontSize: 16, mr: 0.5 }} />
          <Typography variant="body2">@ajeg.co.id</Typography>
          <Language sx={{ fontSize: 16, ml: 2, mr: 0.5 }} />
          <Typography variant="body2">ajeg.co.id</Typography>
        </Grid>
        <Typography variant="body2" textAlign={"center"} sx={{ fontSize: 14 }}>
          Pengaduan pelanggan : pelanggan@ajeg.co.id / 087739070444
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Typography variant="body2" sx={{ fontSize: 13 }}>
            {`Syarat dan ketentuan penukaran barang: `}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {`1. Produk yang sudah dibeli dapat ditukar maksimal dalam waktu 24 jam setelah pembelian.`}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {`2. Penukaran produk hanya berlaku untuk semua produk sofa dan furniture kayu dengan harga sama atau lebih tinggi. Pembeli perlu membayar selisih harga jika produk yang dipilih lebih mahal.`}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {`3. Penukaran produk hanya berlaku untuk produk yang masih dalam kondisi baik.`}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {`4. Produk custom tidak dapat ditukar.`}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {`5. Biaya pengiriman untuk penukaran produk ditanggung sepenuhnya oleh pembeli.`}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {`6. Penukaran produk hanya dapat dilakukan dengan menunjukkan bukti pembelian.`}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12, textAlign: "right" }}>
            {`Tanggal cetak: ${formatDate(new Date())}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ReceiptThermal;
